import * as t from 'io-ts'

import { truncateText } from '@utils/types'
import { Config } from './config'

export const AlternativeDecisionCodec = t.type({
  // 24 character MAX length
  title: truncateText(24),

  // 56 character MAX length
  text: truncateText(56),

  // 24 character MAX length
  cta: truncateText(24),
})

export const isOptional = <A>(codec: t.Type<A>, checkAddToBag?: boolean) =>
  new t.Type(
    codec.name,
    (u: unknown): u is A | undefined => u === undefined || codec.is(u),
    (u, ctx) => {
      const [c] = ctx
      if (u === undefined) {
        if (c.type.name === 'Config' && c.actual) {
          const config = c.actual as Config

          if (
            typeof config.onAlternativeDecisionPath === 'function' &&
            (!checkAddToBag || typeof config.onAddToBag === 'function')
          ) {
            return codec.validate(u, ctx)
          }
        }

        return t.success(u)
      }

      return codec.validate(u, ctx)
    },
    a => (a === undefined ? a : codec.encode(a))
  )
