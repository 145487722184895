import { Config } from './types/config'
import { createRoot, Root } from 'react-dom/client'
import pkgVersion from './version'
import { VMWidgetQRCode } from './qrCodeWidget'

import { isBrowserSupported, isUPCSupported, isValidConfig } from '@utils/helpers'
import { reset } from '@utils/reset'
import { warmUp } from '@utils/warmUp'
import { deprecatedClearPicture } from '@utils/deprecatedClearPicture'
import { VMWidgetRXC } from './widgets/RXCWidget'
import { setDefaultValues } from '@utils/setDefaultValues'

interface IVMWidgetApp {
  config: Config | undefined
  init: () => void
  setConfig: (config: Config) => Promise<void>
  unmount: () => Promise<void>
  reset: () => Promise<void>
}

class VMWidgetApp implements IVMWidgetApp {
  public config: Config | undefined
  private root: Root | null | undefined

  constructor(config?: Config) {
    if (config) {
      // eslint-disable-next-line no-console
      console.warn(
        'Please use setConfig method to set the config, new VMWidgetApp(config) will be deprecated in the future'
      )

      this.config = setDefaultValues(config)
      this.setRoot()
    }
  }

  async init() {
    if (!this.config) {
      throw new Error('Config is not defined')
    }

    if (!this.root) {
      throw new Error(
        `You requested Virtual mirror to render inside the element with the selector ${this.config.selector}, but there is no such element in the document. Check the "selector" parameter in Virtual mirror initialization or your DOM.`
      )
    }

    const { VmWidgetUi } = await import('./VmWidgetUi')
    const { AppLoader } = await import('@components/Loader')

    this.root.render(
      <>
        <AppLoader container={this.config.selector} isDisabled={false} />
        <VmWidgetUi config={this.config} />
      </>
    )
  }

  async setConfig(config: Config) {
    if (this.config) {
      throw new Error('Config is already defined')
    }

    return new Promise<void>((resolve, reject) => {
      isValidConfig(config)
        .then((decoded: Config) => {
          this.config = decoded
          this.setRoot()
          resolve()
        })
        .catch(error => reject({ error }))
    })
  }

  async unmount() {
    if (this.root) {
      try {
        const { unmount } = await import('@utils/unmount')
        await unmount()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      this.root.unmount()
    }
  }

  // to be removed with VMC-421
  async reset() {
    if (this.root) {
      // eslint-disable-next-line no-console
      console.warn(
        // eslint-disable-next-line quotes
        `This method will be removed in the next major release,
        please use namespace\' method instead: vmmv.reset()`
      )
      try {
        reset()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  }

  static new(config: Config) {
    return new VMWidgetApp(config)
  }

  static version() {
    return pkgVersion
  }

  private setRoot() {
    if (this.config) {
      const container = document.querySelector(this.config.selector)
      this.root = container ? createRoot(container) : null
    }
  }
}

export {
  VMWidgetApp,
  VMWidgetQRCode,
  VMWidgetRXC,
  deprecatedClearPicture as clearPictureVideoIds,
  isUPCSupported,
  isBrowserSupported,
  isValidConfig,
  reset,
  warmUp,
}
