import { LensItem } from '@customTypes/lens'

export const setLens = async (id: string) => {
  const { store } = await import('@store/index')
  const { setLens } = await import('@store/actions/rxc')

  const {
    rxc: { lensesList },
  } = store.getState()

  if (lensesList && !!lensesList.length) {
    const lens = lensesList.find(i => i.lensIdentifier === id)

    if (lens) store.dispatch(setLens(lens))
  }
}

export const setLensesList = async (payload: { lensList: LensItem[] }) => {
  const { store } = await import('@store/index')
  const { setLensList } = await import('@store/actions/rxc')

  let isValid = false
  // validate payload
  if (typeof payload === 'object') {
    const lensList = payload.lensList

    if (validateLensList(lensList)) {
      store.dispatch(setLensList(lensList))
      isValid = true
    }
  }

  if (!isValid) {
    // eslint-disable-next-line no-console
    console.error('Invalid lensList data')
  }
}

export const validateLensList = (lensList: LensItem[]) => {
  if (Array.isArray(lensList)) {
    if (!!lensList.length) {
      const hasCorrectProps = lensList.every(
        i => !!i.name && !!i.colorName && !!i.lensIdentifier && !!i.swatchImage
      )

      if (hasCorrectProps) {
        return true
      }
    }
  }

  return false
}
