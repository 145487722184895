import config from '../config'

export const warmUp = async () => {
  const { VirtualMirror } = await import('@luxottica/virtual-mirror')
  VirtualMirror.warmUp(config.vmEnv as Parameters<typeof VirtualMirror.warmUp>[0])

  import(/* webpackPrefetch: true */ '@luxottica/frame-advisor-capture')
  import(/* webpackPrefetch: true */ '@luxottica/vto-core')
  import(/* webpackPrefetch: true */ '@luxottica/virtual-mirror')
}
